<template>
  <v-container full-height style="height: 70vh;">
    <!-- <div class="center"> -->
      <v-row class="pt-5">
        <v-col sm=6 md=6 lg=6></v-col>
        <v-col sm=6 md=6 lg=6 class="text-right">
          <v-icon>
            dark
            right
          >
            mdi-account
          </v-icon>
            {{email.toUpperCase()}}
            <v-btn
              small
              outlined
              color="red"
              to="/signout"
            >
              logout
            </v-btn>
        </v-col>
      </v-row>
      <v-row class="pt-0">
        <v-col sm=12 md=12 lg=12 class="text-center">
          <h1>เลือกห้องที่คุณต้องการจอง</h1>
        </v-col>
        
      </v-row>
      <v-row align="center" class="fill-height justify-center"  style="padding-bottom: 45px;">
        <div v-for="(room, idx) in roomList" :key="room['id']" v-show="true" class="px-5">
          <v-card
            :loading="false"
            class="mx-auto my-12"
            max-width="374"
          >
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            
            <v-img
              height="auto"
              v-bind:src="require('../../public/images/' + pathRoom[idx])"
              contain
              class="blurpastel"
            ></v-img>

            <v-card-title><b>{{room['name']}}</b></v-card-title>

            <v-card-text>

              <div>{{detailRoom[idx]}}</div>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
              <v-row>
                <v-col sm=12 md=12 lg=12>
                  <v-btn
                    v-on:click="goToEzSchedule(room['shortLink'])"
                    color="#f7aaba"
                    block
                  >
                    จองห้อง
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions>
              <v-row>
                <v-col sm=12 md=12 lg=12>
                  <v-btn
                    v-on:click="goToEzBooking(room['shortLink'])"
                    color="#f7aaba"
                    block
                    outlined
                  >
                    ตรวจสอบการจองของท่าน
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </div>
        <v-col sm=12 md=12 lg=6 v-for="room in roomList" :key="room['id']" v-show="false">
          <v-card
            class="mx-auto"
            max-width="344"
            outlined
            elevation="2"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  {{room['name']}}
                </v-list-item-title>
                <v-list-item-subtitle >จองห้อง {{room['name']}}</v-list-item-subtitle>
              </v-list-item-content>

            <v-list-item-avatar rounded size="80" color="#a6c7e6" style='border-radius: 25px;'>
              <!-- <div class="rcorners1 ">

              </div> -->
              <img
                alt="Avatar"
                src="../../public/images/ezstudio-logo.png"
              >
            </v-list-item-avatar>
          </v-list-item>

            <v-card-actions>
              <v-btn
                
                rounded
                
                v-on:click="goToEzSchedule(room['shortLink'])"
                color="#f7aaba"
              >
                จองห้อง
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    <!-- </div> -->
  </v-container>
</template>

<script>
import apiEzBooking from "@/services/apiEzBookingServices"
import md5 from "crypto-js/md5";

export default {
  name: "SelectRoomCalendar",
  props: {
    msg: String,
  },
  data() {
    return {
      superSaasAccount: "tlic",
      apiKey:"",
      email:"",
      roomList: [],
      pathRoom:[
        "EZ-Classroom-1.png",
        "EZ-Classroom-2.png",
        "EZ-Self-1.png",
        "EZ-Self-2.png"
      ] , 
      detailRoom : [
        "Full service easy studio",
        "Full service easy studio",
        "Self service easy studio",
        "Self service easy studio"
      ]
    }
  },
  created() {
    this.apiKey = `${process.env.VUE_APP_SUPERSAAS_API_KEY}`
    this.getSuperSaasUser()
    this.getRoomList()
  },
  methods: {
    async getRoomList() {
      const _roomList = await apiEzBooking.getScheduleList()
      console.log("room : " , _roomList)
      this.roomList = _roomList
    },
    async getSuperSaasUser() {
      let userObj = JSON.parse(localStorage.getItem("user"))
      let superSaasUser = await apiEzBooking.getUserProfile(userObj)
      this.email = `${superSaasUser['name']}`
      console.log("superSaasUser" , superSaasUser)
    },
    async goToEzSchedule(_scheduleName) {
      let _checksum  = md5(`${this.superSaasAccount}${this.apiKey}${this.email}`)
      let _uriOpenSchedule = `https://www.supersaas.com/api/login?account=${this.superSaasAccount}&after=https://www.supersaas.com/schedule/${this.superSaasAccount}/${_scheduleName}&user[name]=${this.email}&checksum=${_checksum}`
      console.log("uri : ", _uriOpenSchedule)
      window.open(_uriOpenSchedule, '_blank');
    },
    async goToEzBooking(_scheduleName) {
      let _checksum  = md5(`${this.superSaasAccount}${this.apiKey}${this.email}`)
      let _uriOpenSchedule = `https://www.supersaas.com/api/login?account=${this.superSaasAccount}&after=https://www.supersaas.com/schedule/${this.superSaasAccount}/${_scheduleName}?view=agenda&user[name]=${this.email}&checksum=${_checksum}`
      window.open(_uriOpenSchedule, '_blank');
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bgcolor {
  background-color: grey;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
